<template>
  <div :class="getClasss">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line">
            <el-form-item label="药品名称:">
              <el-input size="small" v-model="searchForm.inputsName" placeholder="请输入药品名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="产品名称:">
              <el-input size="small" v-model="searchForm.productName" placeholder="请输入产品名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="产品生产批次:">
              <el-input size="small" v-model="searchForm.docNum" placeholder="请输入产品生产批次" clearable></el-input>
            </el-form-item>
            <el-form-item label="批次开始生产日期:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange"
                @change="timeChange"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="药品使用日期:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange2"
                @change="timeChange2"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="title-tab">
        <div class="title-tab-btns">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="download">导出</el-button>
        </div>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
            <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <drugs-edit ref="editForm" @getData="getData"></drugs-edit>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import drugsEdit from './drugsEdit.vue'
  import * as XLSX from "xlsx"
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'drugsList',
    components: {
      pagination,
      breadcrumb,
      drugsEdit
    },
    data() {
      let title = '药品使用'
      return {
        timeRange: '',
        timeRange2: '',
        loadtext: '加载中',
        breadStaticData:['生产档案管理', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId(),
          inputsName: '',
          productName: '',
          docNum: '',
          startTime: '',
          endTime: '',
          useStartTime: '',
          useEndTime: '',
        },
        urlName: 'findalldrugsutilization',
        //药品名称、药品登记批次编号、已使用产品、产品生产批次编号、使用日期、使用数量、使用重量、施用人、施用方法、防治对象、安全间隔期、安全采收期、操作（修改、删除、查看）
        tableHead: [{
          prop: 'inputsName',
          label: '药品名称',
          width: 150
        },{
          prop: 'registerBatchCode',
          label: '药品登记批次编号'
        },{
          prop: 'productName',
          label: '已使用产品'
        },{
          prop: 'docNum',
          label: '产品生产批次编号'
        },{
          prop: 'useDate',
          label: '使用日期',
          width: 100
        },{
          prop: 'useNum',
          label: '使用数量',
          width: 120
        },{
          prop: 'useWeight',
          label: '使用重量',
          width: 120
        },{
          prop: 'applyer',
          label: '施用人'
        },{
          prop: 'useTypeName',
          label: '施用方法',
          width: 100
        },{
          prop: 'precaution',
          label: '防治对象'
        },{
          prop: 'quarantinePeriod',
          label: '安全间隔期',
          width: 100
        },{
          prop: 'harvest',
          label: '安全采收期',
          width: 100
        }],
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        },
        getClasss: utils.getClientFlag() ? 'page-contain my-table-list' : 'page-contain'
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      download(){
        let arr = this.listData,
        tableData = [['序号', '药品名称', '药品登记批次编号', '已使用产品', '产品生产批次编号', "使用日期", "使用数量",  "使用重量",  "施用人",  "施用方法",  "防治对象",  "安全间隔期",  "安全采收期"]] // 表格表头
        if(!arr || !arr.length){
          this.message('暂无数据，无法导出！', "warning")
          return
        }
        arr.forEach((item, index) => {
          let rowData = []
          rowData = [
            index + 1,
            item.inputsName,
            item.registerBatchCode,
            item.productName,
            item.docNum,
            item.useDate,
            item.useNum,
            item.useWeight,
            item.applyer,
            item.useTypeName,
            item.precaution,
            item.quarantinePeriod,
            item.harvest,
          ]
          tableData.push(rowData)
        })
        this.loading = true
        this.loadtext = '导出中'
        let workSheet = XLSX.utils.aoa_to_sheet(tableData);
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, '数据') // 工作簿名称
        let name = this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx'
        XLSX.writeFile(bookNew, name) 
        this.loadtext = '加载中'
        this.loading = false
      },
      timeChange(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0])
          endTime = utils.dateFormat(val[1])
        }
        this.searchForm.startTime = startTime
        this.searchForm.endTime = endTime
      },
      timeChange2(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0])
          endTime = utils.dateFormat(val[1])
        }
        this.searchForm.useStartTime = startTime
        this.searchForm.useEndTime = endTime
      },
      async initTable(){
        this.getData()
      },
      async add(){
        // let id = await utils.getNewId(),
        //   obj = {
        //   ...this.initForm,
        //   batchId: id
        // }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add')
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data){
        let _this = this
        request.finddrugsutilization({
          useId: data.useId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              imageUrl: result.imageUrl ? result.imageUrl.split(',') : [],
              videoUrl: result.videoUrl ? result.videoUrl.split(',') : [],
              useDate: result.useDate ? utils.dateFormat(result.useDate, 'yyyy-MM-dd') : '',
              harvest: result.harvest ? utils.dateFormat(result.harvest, 'yyyy-MM-dd') : '',
              registerBatchCode: result.registerBatchCode && result.registerBatchCode.indexOf('(') > -1 ? result.registerBatchCode.split('(')[0] : result.registerBatchCode,
              show: result.show == '01' ? true : false
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data){
        let _this = this
        _this.$confirm(`确认要删除${data.inputsName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.deletedrugsutilization({
            useId: data.useId,
            managementId: data.managementId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                useDate: utils.dateFormat(item.useDate, 'yyyy-MM-dd'),
                harvest: utils.dateFormat(item.harvest, 'yyyy-MM-dd'),
                useTypeName: utils.getConstToVal('useType', item.useType),
                useNum: (item.useNum || '') + (item.useNumUnit || ''),
                useWeight: (item.useWeight || '') + (item.useWeightUnit || '')
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
</style>